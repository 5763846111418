.component-category-cards-container {
    padding: 0.5rem 2rem;
    height: calc(100vh - 7.2rem);
    overflow-y: auto;
}

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}