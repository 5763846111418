.measurement-list-item-image-thumbnail {
    width     : 15rem;
    height    : 100%;
    object-fit: cover;
    flex      : 1;
  }
  
  .measurement-list-item-image-large {
    height    : 50rem;
    object-fit: cover;
  }
  
  .measurment-list-item-list-item {
    cursor    : pointer;
    height    : 10rem;
    min-height: 10rem;
  }