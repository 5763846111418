.detail-view-container {
    margin: 0.8rem;

    .title {
        margin: 0 0 0.8rem 0;
        font-size: 16px;
        font-weight: bold;
    }

    .detail-project-container {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        p {
            font-size: 14px;
            color: #333;
        }

        .button-container {
            margin: 0 0 0 0.8rem;
        }
    }

    .empty-detail-project-container {
        p {
            font-size: 14px;
            color: #333;
        }
    }
}