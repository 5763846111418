.page-two-legal-wrapper {
  width: 100%; 
  margin-left: -3rem;
}

.page-two-text {
  color: var(--dark-blue-dark);
  font-size: var(--caption-font-size);
  font-weight: var(--caption-font-weight);
  line-height: var(--caption-line-height);
  font-family: var(--main-font);
  display: inline;
}

.page-two-link {
  color: var(--primary);
  font-size: var(--caption-font-size);
  font-weight: var(--caption-font-weight);
  line-height: var(--caption-line-height);
  font-family: var(--main-font);
}

.page-two-link:hover {
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}