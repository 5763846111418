

.project-preview-thumbnail {
    width: 8rem;
    border-radius: 2rem;
    background-color: var(--dark-blue-medium);
    height: 8rem;
  }

.project-file-preview-file-name {
    width: 10rem;
    height: 1.5rem;
    background-color: var(--dark-blue-medium-light);
  }

  .project-file-preview-inserted-at {
    width: 10rem;
    height: 1.5rem;
    background-color: var(--dark-blue-medium-light);
  }

  .project-file-preview-buttons {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 3.5rem;
    background-color: var(--dark-blue-medium-light);
    margin-left: auto;
    margin-right: 1.6rem;
  }

.project-preview-spacing {
  margin-top: 1rem;
}

.project-file-list-item-truncate-text { 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }


.shimmer {
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/350% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
  }
  
  @keyframes shimmer {
    100% {-webkit-mask-position:left}
  }
  
