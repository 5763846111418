/* roboto-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../public/fonts/roboto/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/roboto/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/roboto/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/roboto/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/roboto/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/roboto/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/roboto/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/roboto/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/roboto/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/roboto/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../public/fonts/roboto/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/roboto/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/roboto/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/roboto/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/roboto/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/roboto/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

:root {
  --box-shadow: 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.23), 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.16);

  --status-synchronized: rgba(39, 173, 232, 1);
  --status-not-synchronized: rgba(229, 5, 1, 1);
  --status-done: rgba(126, 211, 33, 1);
  --status-wip: rgba(245, 166, 35, 1);
  --status-open: rgba(229, 5, 1, 1);

  --header-height: 6.4rem;
  --project-header-menu-height: 7.4rem;
  --project-statistics-height: 29rem;

  --primary: #5953D2;
  --primary-dark: #443F9E;
  --secondary: #853BD1;
  --dark-blue-base: #1F2633;
  --dark-blue-dark: #3C424D;
  --dark-blue-medium-dark: #868A91;
  --dark-blue-medium: #AAADB1;
  --dark-blue-medium-light: #E3E4E5;
  --dark-blue-light: #F0F1F1;
  --dark-blue-super-light: #F8F8F9;
  --white: #FFFFFF;
  --black: #000000;
  --red: #FF0400;
  --redClick: #B20300;
  --green: #2DA50B;
  --greenClick: #207C06;

  --main-font: 'Roboto', sans-serif;

  --body1-font-size: 1.6rem;
  --body1-font-weight: 400;
  --body1-line-height: 2.4rem;

  --subtitle2-font-size: 1.6rem;
  --subtitle2-font-weight: 500;
  --subtitle2-line-height: 2.4rem;
  
  --caption-font-size: 1.2rem;
  --caption-font-weight: 400;
  --caption-line-height: 1.4rem;

  --spacing-08: 0.8rem;
  --spacing-16: 1.6rem;
  --spacing-24: 2.4rem;
  --spacing-32: 3.2rem;

}

html {
  font-size: 62.5%;
  background-color: var(--dark-blue-super-light);
  height: 100%;
  min-height: 100%;
}

input {
  border: 0 transparent;
  background-color: transparent;
  font-family: var(--main-font);
  font-size: var(--font-small);
  padding: 0;
  letter-spacing: 0.01rem;
}

textarea {
  letter-spacing: 0.01rem;
}

/* Placeholder color in <input /> */

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: var(--dark-blue-medium);
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: var(--dark-blue-medium);
  opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: var(--dark-blue-medium);
  opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--dark-blue-medium);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--dark-blue-medium);
}

::placeholder { /* Most modern browsers support this now. */
  color: var(--dark-blue-medium);
}

input, select, textarea {
  color: var(--dark-grey);
}

*:focus {
  outline: none;
}

p {
  margin: 0;
  letter-spacing: 0.01rem;
}

button {
  border-width: 0;
}

ul, ol, li {
  list-style: none;
}

body {
  overflow: hidden;
  height: 100%;
  min-height: 100%;
}

*, *:before, *:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


.tooltip-styling {
  font-family: var(--main-font);
  font-size: var(--font-small);
  color: var(--dark-grey);
  background-color: none;
}

.tooltip-styling > .rc-tooltip-content > .rc-tooltip-inner {
  background-color: white;
}

.tooltip-edit-comment > .rc-tooltip-content > .rc-tooltip-inner {
  padding: 0;
}

.header-right-side {
  display: flex;
  margin: 0 calc(var(--spacing-32) * 2.5) 0 auto;
  height: 3.8rem;
  align-items: center;
  flex: 0 0 auto;
}

.header-left-side {
  display: flex;
  margin: 0 20px 0 calc(var(--spacing-32) * 1.5);
  height: 3.8rem;
  align-items: center;
  flex: 0 1 auto;
  min-width: 0;
}

#root{
  height: 100%;
  min-height: 100%;
}
/* DIALOGS */

.dialog-container {
  width: 60rem;
  overflow-x: hidden;
}

.MuiSnackbar-root .MuiSnackbarContent-root{
  background: white;
  color:#222;
}