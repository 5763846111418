.splash-screen-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: var(--dark-blue-super-light);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.splash-screen-particles-wrapper > div {
  width: 100vw;
  height: 100vh;
}

.splash-screen-particles-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-image: linear-gradient(135deg, #170a50, #6f2274);
}
