.layout-conainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
}

.layout-container-full-app-height { 
  height: calc(100vh - 7.2rem);
}

.layout-container-with-padding {
  padding: 5% 10%;
}

.layout-container-withoug-padding {
  padding: 0;
}