
.columns-container {
  width: 45%;
  display: flex;
}

.columns-left-side {
  margin-right: 2.4%;
}

.columns-right-side { 
  margin-left: 2.4%;
}