.project-list-list {
  display: flex;
  flex-direction: column;
  padding-top: 0.8rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.project-list-center-content {
  height: calc(100vh - 8rem);
  justify-content: center;
  align-items: center;
}

.project-list-show-list {
  max-height: calc(100vh - 14rem);
  height: calc(100vh - 14rem);
}

.project-list-show-list.header-open {
  max-height: calc(100vh - 20rem);
  height: calc(100vh - 20rem);
}

.projectsEmpty {
  text-align: center;
  margin-top: 24px;
  padding-top: 25px;
}
