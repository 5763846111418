.state-indicator-container {
  height: 1.8rem;
  border-radius: 1.2rem;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  font-family: var(--main-font);
  font-size: var(--caption-font-size);
  line-height: var(--caption-line-height);
  font-weight: var(--caption-font-weight);
}

.state-indicator-text {
  padding: 0 1.2rem;
  min-width: 5rem;
}