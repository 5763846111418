.bulk-actions-menu-container {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.bulk-actions-menu-container > label {
  margin-left: 1.6rem;
}

.bulk-actions-menu-container > div {
  display: flex;
}

.bulk-actions-menu-container > div > span {
  margin: 0 0.5rem;
}

.bulk-actions-menu-display {
  display: flex;
  height: 6rem;
  -webkit-transition: visibility 0s, height 0.2s ease-in-out;
}

.bulk-actions-menu-hide {
  width: 100%;
  height: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s, height 0.2s ease-in-out;
}
