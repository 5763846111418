.custom-pagination-main {
    display: flex;
    justify-content: center;
    margin-top: calc(4rem/2);
}

.project-counter {
    position: absolute;
    bottom: calc((4rem/2) + (8px/2));
    right: 20px;
}