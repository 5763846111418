.divider-container {
  width: 100%;
  display: flex; 
  justify-content: center;
  padding: 5rem 0;
}

.divider-line {
  border-style: solid;
  border-width: 0 0 0.2rem 0; 
  border-color: var(--dark-blue-medium-light);
  width: 100%;
}