.button-group-container {
  display: flex;
  width: 100%;
}

.button-group-spacing-top {
  margin-top: var(--spacing-24);
}

.button-group-spacing-bottom {
  margin-bottom: var(--spacing-24);
}

.button-group-align-right {
  margin-left: auto;
}

.button-group-align-left {
  margin-right: auto;
}

.button-group-align-evenly {
  justify-content: space-evenly;
}

.button-group-align-center {
  justify-content: center;
}