:root {
    --circle-color: #000
}

.clickable-circle-holder-xs {
    height          : 36px;
    width           : 36px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    margin          : 4px;
}

.clickable-circle-holder-xm {
    height          : 48px;
    width           : 48px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    margin          : 4px;
}

.clickable-circle-holder-xl {
    height          : 60px;
    width           : 60px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    margin          : 4px;
}
    

.clickable-circle {
    background-color: var(--circle-color);
    border-radius   : 50%;
}

.clickable-circle-selected {
    border:6px solid var(--circle-color);
    border-radius: 50%;
}

.clickable-circle-xs {
    height          : 24px;
    width           : 24px;
    transition: width 0.2s, height 0.2s;
}

.clickable-circle-xm {
    height          : 36px;
    width           : 36px;
    transition: width 0.2s, height 0.2s;
}

.clickable-circle-xl {
    height          : 48px;
    width           : 48px;
    transition: width 0.2s, height 0.2s;
}

.clickable-circle-xs:hover {
    height          : 36px;
    width           : 36px;
}

.clickable-circle-xm:hover {
    height          : 48px;
    width           : 48px;
}

.clickable-circle-xl:hover {
    height          : 60px;
    width           : 60px;
}