.project-preview-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
}

.project-preview-job-number {
  width: 10rem;
  height: 1.5rem;
  background-color: var(--dark-blue-medium-light);
}

.project-preview-state-indicator {
  width: 7rem;
  border-radius: 2rem;
  background-color: var(--dark-blue-medium);
  height: 2rem;
}

.project-preview-headline {
  width: 15rem;
  height: 1.5rem;
  background-color: var(--dark-blue-medium)
}

.project-preview-description {
  width: 20rem;
  height: 1.5rem;
  background-color: var(--dark-blue-medium-light);
}

.project-preview-created-at {
  width: 5rem;
  height: 1rem;
  background-color: var(--dark-blue-medium);
}

.project-preview-created-at-date {
  width: 9rem;
  height: 1rem;
  background-color: var(--dark-blue-medium-light);
}

.project-preview-edited-at {
  width: 5rem;
  height: 1rem;
  background-color: var(--dark-blue-medium);
}

.project-preview-edited-at-date {
  width: 9rem;
  height: 1rem;
  background-color: var(--dark-blue-medium-light);
}

.project-preview-shine {
    background-color: white;
    filter: blur(2rem);
    animation: preview-shine 2s infinite;
    height: 8rem;
    width: 10%;
    position: absolute;
    z-index: 999;
}

.project-preview-spacing {
  margin-top: 1rem;
}

@keyframes preview-shine {
    from { left: 0 }
    to { left: 100% }
}
