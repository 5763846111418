.search-button {
  position: relative;
  border-radius: 0.3rem;
  background-color: var(--dark-blue-medium-light);
  height: 100%;
  width: 100%;
  margin-left: 0 !important;
}

.search-button-icon {
  width: 3.5rem;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}