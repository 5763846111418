.password-field-strength-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.password-field-strength-wrapper:after {
    content: "";
    flex: auto;
}

.password-field-strength {
    align-self: flex-start;
}

.password-field-strength-requirements-wrapper {
    display: flex;
    align-items: center;
    width: 50%;
    margin: 0 0 1rem;
}

.password-field-strength-circle {
    margin: 0 1rem 0 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: var(--dark-blue-medium-light);
}

.password-field-fullfilled {
    background-color: var(--primary);
}

.password-field-input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.password-field-visbility-icon {
    font-size: 2rem !important;
    color: var(--dark-blue-medium-dark);
    cursor: pointer;
}

.password-field-icon-click-area {
    display: flex;
    position: absolute;
    right: 0;
}

.password-field-icon-click-area:hover .password-field-visbility-icon {
    color: var(--dark-blue-medium-dark);
}


@media screen and (max-width: 380px) {
    .password-field-strength-requirements-wrapper {
        width: 100%;
    }
}