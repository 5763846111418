.component-catalog-table-content {
    max-height: calc(100vh - 26rem);
    height: calc(100vh - 26rem);
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}