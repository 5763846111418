.screenshot-list-item-image-thumbnail {
  width     : 15rem;
  height    : 100%;
  object-fit: cover;
  flex      : 1;
}

.screenshot-list-item-image-large {
  height    : 50rem;
  object-fit: cover;
}

.screenshot-list-item-list-item {
  cursor    : pointer;
  height    : 10rem;
  min-height: 10rem;
}

.image-modal-image {
  object-fit: contain;
  max-height: 90vh;
}