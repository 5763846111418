.invite-new-user-button-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: var(--spacing-24) 0 0 0;
}

.invite-new-user-input-wrapper {
  margin: var(--spacing-24) 0 0 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.invite-new-user-user {
  display: flex;
  width: 100%;
  margin: var(--spacing-24) 0;
}

.invite-new-user-add-button-wrapper {
  display: flex;
  margin: 1rem auto 0 0;
  align-items: center;
  cursor: pointer;
}
