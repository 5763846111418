.dialog-text {
  font-size: var(--body1-font-size);
  font-weight: var(--body1-font-weight);
  line-height: var(--body1-line-height);
  font-family: var(--main-font);
  color: var(--dark-blue-dark);
}

.dialog-text-highlight {
  font-size: var(--subtitle2-font-size);
  font-weight: var(--subtitle2-font-weight);
  line-height: var(--subtitle2-line-height);
  font-family: var(--main-font);
  color: var(--dark-blue-base);
}