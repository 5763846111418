.root {
  background-color: white;
  display: flex;
  justify-content: center;
}

.confirm-user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 60vw;
  height: 100vh;
  flex-direction: column;
  padding: 5%;
}

.confirm-user-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.app-download-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.google-play-store-badge {
  width: 200px;
  margin-top: 20px;
}

.qr-code {
  width: 300px;
}