.map-pop-up-image {
  width: 15rem;
  height: 15rem;
  border-radius: 7.5rem;
  margin: 1rem;
  object-fit: cover;
  cursor: pointer;
}

.map-pop-up-empty-image {
  width: 15rem;
  margin: 1rem;
}

.map-pop-up-image-container {
  text-align: center;
}

.map-pop-up-title {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}

.map-pop-up-text {
  margin-bottom: 6px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
}

.map-pop-up-custom-mapgoxgl-pop-up {
  font-family: var(--main-font);
}

.custom-mapboxgl-popup-content .mapboxgl-popup-close-button {
  border-radius: 5px;
  margin: 1rem;
  font-size: 20px;
  font-weight: 700;
}

.custom-mapboxgl-popup-content .mapboxgl-popup-content{
  border-radius: 10px;
  padding: 1rem;
}