.log-in-text-input-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.log-in-text-input {
  width: 100%;
}

.log-in-forgot-password {
  color: #868A91;
  margin: 1rem auto 3rem 0;
  cursor: pointer;
  width: 100%;
}

.log-in-already-registred {
  width: 100%;
  text-align: center;
}

.log-in-password-field-visbility-icon {
  font-size: 2rem !important;
  color: var(--White5);
  cursor: pointer;
}

.log-in-password-field-input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}

.log-in-password-field-icon-click-area {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 1.2rem;
}

.log-in-password-field-icon-click-area:hover
  .log-in-password-field-visbility-icon {
  color: var(--dark-blue-base);
}

.log-in-remember-me-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.log-in-register-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-height: 250px) {
  .log-in-icon {
    font-size: 1.5rem !important;
  }
}
