.gnss-polygon-list-item-image-container {
  height: 100%;
  display: flex;
}

.gnss-polygon-list-item-image-thumbnail {
  height    : 100%;
  object-fit: cover;
  flex      : 1;
}

.gnss-polygon-list-item-image-large {
  height    : 50rem;
  object-fit: cover;
}

.gnss-polygon-list-item-list-item {
  cursor    : pointer;
  height    : 10rem;
  min-height: 10rem;
}