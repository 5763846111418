.project-info-wrapper {
  padding: 0 0 2.4rem 0;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.project-info-headline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--spacing-24);
  word-break: break-word;
}

.project-info-download-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 0 0 0;
}