.header-container {
  min-height: 7.2rem;
  box-shadow: var(--box-shadow);
  z-index: 200;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.header-container-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0rem 2.5rem;
  height: 7.2rem;
}

.header-container-divider {
  height: 1px;
  background-color: rgba(128, 128, 128, 0.2);
}
