.user-avatar {
  width: 20rem;
  height: 20rem;
  margin: 0 0 1rem 0;
  border-radius: 50%;
}

.user-avatar-image {
  object-fit: cover;
}

.user-no-avatar {
  border-style: dashed;
  border-width: 0.2rem;
  border-color: var(--dark-blue-medium-dark);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--dark-blue-medium-dark);
  font-family: var(--main-font);
}

.user-avatar-text {
  text-align: center;
  white-space: pre-line;
  font-size: var(--caption-font-size);
  font-weight: var(--caption-font-weight);
  line-height: var(--caption-line-height);
  font-family: var(--main-font);
}

.user-no-avatar:hover {
  border-color: var(--dark-blue-dark);
  color: var(--dark-blue-dark);
}

.user-avatar-icon-button-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: var(--spacing-08) 0 0 0;
}

.user-avatar-edit-avatar-wrapper {
  width: 100%;
  display: flex;
}

.user-avatar-edit-avatar-positioning {
  margin: 5rem 5rem 0px auto;
}