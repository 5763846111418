.header-user-administration-counter-wrapper {
  margin: 0 var(--spacing-24) 0 0;
}

.header-user-administration-more-user:hover {
  cursor: pointer;
  text-decoration: underline;
}

.header-user-administration-right-side {
  display: flex;
}