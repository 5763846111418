.circle {
    background-color: #bbbbbb;
    border-radius   : 50%;
    margin-right    : 30px;
}

.circle-xs {
    height          : 24px;
    width           : 24px;
}

.circle-xm {
    height          : 36px;
    width           : 36px;
}

.circle-xl {
    height          : 48px;
    width           : 48px;
}