.center-content {
    display: flex;
    align-items: center;
}

.text-field-styling {
    margin: 1.2rem 0 2.4rem 0;
    padding-left: 1.2rem;
}

.string-set-title {
    margin: 2.4rem 0 1.2rem 0;
}