.project-container {
  display: flex;
  flex-direction: row;
  height: 100vh
}

.project-bottom-container {
  flex: 2;
  min-width: 50vw;
}

.project-search-position {
  position: absolute;
  left: 0;
  z-Index: 9999;
  width: 100%;
  background-color: var(--dark-blue-super-light);
  max-height: 45vh;
  overflow: auto;
}