.project-list-item-vertical-divider {
  background-color: var(--dark-blue-medium-light);
  width: 0.1rem;
  height: 5.5rem;
  margin: 0.6rem;
}

.project-list-item-dropdown-icon {
  min-width: 0;
}

.project-list-item-jobnumber-state-wrapper {
  display: flex;
  position: relative;
}

.project-list-item-title-description-wrapper {
  width: 35%;
}

.project-list-item-truncate-text { 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.project-list-item-row-wrapper {
  margin-bottom: 1.5rem;
}

.project-list-item-date-sections {
  width: 10%;
}

.project-list-item-secondary-action {
  width: 3%;
}