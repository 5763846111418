.gnss-image-list-item-image-container {
    height: 100%;
    display: flex;
}
  
.gnss-image-list-item-image-thumbnail {
    height    : 100%;
    object-fit: cover;
    flex      : 1;
}

.gnss-image-list-item-image-large {
    height    : 50rem;
    object-fit: cover;
}

.gnss-image-list-item-list-item {
    cursor    : pointer;
    height    : 10rem;
    min-height: 10rem;
}