.custom-modal-paper {
  display: flex;
  position: absolute;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  outline: none;
  max-height: 90vh;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
}
