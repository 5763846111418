.mapboxgl-popup-close-button {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapboxgl-popup-close-button:hover {
  background-color: var(--dark-blue-medium) !important;
}

.image-modal-image {
  object-fit: contain;
  max-width: 40rem;
}
