.personal-information-element-container {
  display: block;
  margin: var(--spacing-24) 0;
  width: 100%;
  position: relative;
}

.MuiTypography-root.registration-log-in-link {
  padding-bottom: 20px;
}
.registration-log-in-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
