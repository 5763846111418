.landing-page-container {
  display: flex;
  height: 100vh;
}

.landing-page-left-side {
  width: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: white;
  overflow: auto;
  
}

.landing-page-right-side {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem;
}

.landing-page-left-side-logo-wrapper {
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  width: 50%;
  padding: 2.4rem 2.4rem 1rem 2.4rem;
  z-index: 9999;
}

.landing-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  padding: 11rem 1rem 1.5rem 1rem;
}

.intro-banner-img {
  position: relative;
  flex: 1 0 52%;
}

.promo-video {
  position: absolute;
  top: 28.863636364%;
  left: 2.016806723%;
  z-index: 1;
  width: 100%;
  max-width: 23.529411765%;
  height: 0;
  padding-bottom: 47%;
  overflow: hidden;
  border-radius: 5.6%/2.8%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.video-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  display: inline-flex;
  align-items: center;
}

.play-pause {
  position: relative;
  z-index: 1;
  font-size: 3rem;
  margin: 0 0 0 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
}

.play-pause,
.request-fullscreen:-moz-focusring,
.play-pause,
.request-fullscreen::-moz-focus-inner {
  outline: none;
  border: none;
}

.play-pause:before {
  content: "\f357";
  font-family: "Ionicons";
  color: var(--white);
  text-shadow: 0px 0px 10px White100;
  animation: 400ms glow alternate infinite ease;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.play-pause:before {
  margin-right: calc(18px + (21 - 18) * (100vw - 320px) / (1200 - 320));
}

.promo-video.play .play-pause:before {
  content: "\f341";
  display: none;
}

.promo-video.play {
  display: none;
}

.promo-video.play:hover .play-pause:before {
  display: block;
}

.promo-video.play:hover {
  display: block;
}

.particles-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-image: linear-gradient(135deg, #2c6dd4, #853bd1);
  z-index: -1;
}

.landing-page-img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 1160px) {
  .landing-page-right-side {
    display: none;
  }
  .landing-page-left-side {
    width: 100%;
  }
  .landing-page-wrapper {
    width: 70%;
  }
  .landing-page-left-side-logo-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 674px) {
  .landing-page-wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 380px) {
  .landing-page-button {
    width: 100%;
    margin: 0.5rem 0;
  }

  .password-field-strength-requirements-wrapper {
    width: 100%;
  }
}
